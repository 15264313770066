import * as React from 'react';
import  "./multiEditStyles.css";
import ReactTable, { Column, Instance, CellInfo, Filter } from 'react-table';
import "react-table/react-table.css";
import CrmUpload from '../crm-upload-quote/crm-upload';

const PriceLine = {
  ManualPrice: "062.00",
  DiscountPercent: "224.00",
  DiscountAmount: "226.00"
};

interface CustomLine {
  lineId: string,
  parentId: string,
  lineNumber: number,
  material: string,
  longDescription: string,
  quantity: number,
  listPrice: number,
  manualPrice: number,
  amount: number,
  discount: number,
  extendedAmount: number,
  discountMethod: string,
  discountType: string,
  comment: string,
  billCreditFlag: boolean,
  shipReceiveFlag: boolean,
  warehouse: string,
  requestedDeliveryDate: string,
  system: string,
  contract: string,
  reasonCode: string,
  serial: string,
  readOnlyPrice: string,
  readOnlyDiscount: string,
  selected: boolean
}

interface CustomGroup {
  id: string,
  description: string
}

interface CustomQuote {
  messages: string[],
  groups: any,
  lines: CustomLine[]
}

interface ReactTableCustomLine {
  _original: CustomLine;
}

interface MultiEditRequest {
  documentId: string,
  revision: number,
  lineIds?: string[],
  oldQuote?: CustomQuote,
  key?: any,
  value?: any
}

interface IQuteCustomEditState {
  lines: CustomLine[],
  groups: CustomGroup[],
  messages: string[],
  expanded: boolean,
  spreadAmount: number,
  newProductGroup: string,
  newProduct: string,
  productList: any[],
  isOpen: boolean,
  loading: boolean,
  loadingNewSequence: boolean
}

class MultiEditUI extends React.Component<Quote.IComponentProps, IQuteCustomEditState> {
  constructor(props: Quote.IComponentProps) {
    super(props);
    console.log("props", props);
    this.state = {
      lines: [],
      groups: [],
      messages: [],
      expanded: false,
      spreadAmount: -1,
      newProductGroup: '',
      newProduct: '',
      productList: [],
      isOpen: false,
      loading: true,
      loadingNewSequence: false
    };
  }
  selectTable: Instance<CustomLine> = null;
  
  componentDidMount = () => {
    this.multiEditRequest("GetQuote").then(() => this.setState({loading: false}));
  }

  getFilteredRows = (): CustomLine[] => this.selectTable == null ? [] : this.selectTable.getResolvedState().sortedData.map((l: ReactTableCustomLine) => l._original);

  getSelectedLines = (lineId?: string) => this.getFilteredRows().filter(l => l.selected || l.lineId === lineId);

  getSelectedLineIds = (lineId?: string) => this.getSelectedLines(lineId).map(l => l.lineId);

  multiEditRequest = async (endpoint: string, lineIds?: string[], key?: any, value?: any) => {
    const { axios, quote: { documentId, revision} } = this.props;
    const { lines, groups, messages } = this.state;
    let data: MultiEditRequest = {
      documentId: documentId,
      revision: revision,
      oldQuote: {
        lines: lines,
        groups: groups,
        messages: messages
      }
    };
    if (typeof lineIds !== "undefined")
      data.lineIds = lineIds;
    if (typeof key !== "undefined")
      data.key = key;
    if (typeof value !== "undefined")
      data.value = value;
    
    let response = await axios({
      method: "post",
      url: "/api/ext/MultiEdit/" + endpoint,
      data: data
    });

    if (response.status == 200) {
      if ("error" in response.data) {
        console.error("Error returned from multi edit request", data, response.data.error);
        return null;
      }
      let quote = response.data;
      this.setState({
        lines: quote.lines,
        groups: quote.groups,
        messages: quote.messages
      });
      return quote;
    }
    else {
      console.error("Error making multi edit request", data, response);
      return null;
    }
  }

  multiSetProperty = async (lineId: string, propertyName: string, value: any) => {
    return await this.multiEditRequest("SetProperty", this.getSelectedLineIds(lineId), propertyName, value);
  }

  multiSetParentId = async (lineId: string, parentId: string) => {
    this.setState({loadingNewSequence: true})
    var res = await this.multiEditRequest("SetParentId", this.getSelectedLineIds(lineId), parentId);
    this.setState({loadingNewSequence: false});
  }

  multiSetMoveLine = async (lineId: string, up: boolean) => {
    this.setState({loadingNewSequence: true})
    var res = await this.multiEditRequest("MoveLine", [lineId], up);
    this.setState({loadingNewSequence: false});
  }

  multiSetQuantity = async (lineId: string, quantity: number) => {
    return await this.multiEditRequest("SetQuantity", this.getSelectedLineIds(lineId), quantity);
  }

  multiSetPriceStep = async (lineId: string, priceStep: string, price?: number) => {
    return await this.multiEditRequest("SetPriceStep", this.getSelectedLineIds(lineId), priceStep, price);
  }

  multiSetDescription = async (lineId: string, description: string) => {
    return await this.multiEditRequest("SetDescription", [lineId], description);
  }

  multiDeleteLines = async (lineId: string) => {
    this.setState({loadingNewSequence: true})
    var res = await this.multiEditRequest("DeleteLines", [lineId]);
    this.setState({loadingNewSequence: false});
  }

  formatNumber = (n: number, d?: number) => {
    if (typeof d === "undefined")
      d = 2;
    if (typeof n !== "number") {
      try {
        n = parseFloat(n);
      }
      catch(err) {
        return "";
      }
    }
    if (isNaN(n))
      return "";

    var parts = n.toFixed(d).toString().split(".");
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : "");
  }

  parseFormatted = (str: string) => {
    if (typeof str == "undefined" || str == null)
      return null;
    return parseFloat(str.replace(/\,/g, ""));
  }

  textFilter = (filter: Filter, row: ReactTableCustomLine, column: Column<CustomLine>) => {
    let result = 0;
    let rowValue = (row[filter.id] + "");
    if (filter.id == "parentId") {
      if (row[filter.id] != null) {
        let group = this.state.groups.find(g => g.id == row[filter.id]);
        if (group != null)
          rowValue = group.description;
      }
    }
    else if (filter.id.endsWith("Date") && rowValue != null && rowValue != "") {
      try {
        let d = new Date(rowValue); //dates come in as YYYY-MM-DDTHH:MI:SSZ, but are displayed as MM/DD/YYYY
        let month = d.getMonth()+1;
        let day = d.getDate();
        rowValue = (month < 10 ? "0" : "") + month + "/" + (day < 10 ? "0" : "") + day + "/" + d.getFullYear();
      }
      catch(err) {
      }
    }
    result = rowValue.toUpperCase().indexOf(filter.value.toUpperCase());
    if (result < 0) {
      row._original.selected = false;
      return false;
    }
    else {
      return true;
    }
  }

  shouldComponentUpdate = (nextProps, prevState) => {
    return true;
  }

  handleOpen = () => {
    this.setState({isOpen: true});
  }

  handleClose = () => {
    this.setState({isOpen: false});
    this.multiEditRequest("GetQuote");
  }

  renderEditableQuantity = (cellInfo: CellInfo) => {
    const { UI } = this.props;
    return (
      <UI.ValidatedInput
        onChange={(value: string) => {
          if (typeof value == "undefined")
            value = "";
          var num = this.parseFormatted(value);
          this.multiSetQuantity(cellInfo.original.lineId, num);
        }}
        value={this.state.lines[cellInfo.index][cellInfo.column.id]}
      />
    );
  }

  renderEditableDiscount = (cellInfo: CellInfo) => {
    const { UI, icons } = this.props;
    const { readOnlyDiscount, discount, discountMethod } = this.state.lines[cellInfo.index];
    if (readOnlyDiscount != null) {
      var text = "";
      if (discount != null && discount != 0)
        text = this.formatNumber(discount, 2);
      return (<span>
        <UI.Icon 
          icon={icons.info}
          tooltip={readOnlyDiscount}
        />
        {text}
      </span>);
    }
    return (
      <UI.ValidatedInput
        onChange={(value: string) => {
          if (typeof value == "undefined")
            value = "";
          var discountValue = this.parseFormatted(value);
          this.multiSetPriceStep(cellInfo.original.lineId, discountMethod, discountValue);
        }}
        value={this.formatNumber(discount, 2)}
      />
    );
  }

  renderEditableManualPrice = (cellInfo: CellInfo) => {
    const { UI, icons } = this.props;
    const { readOnlyPrice, manualPrice } = this.state.lines[cellInfo.index];
    if (readOnlyPrice)
      return (
        <UI.Icon 
          icon={icons.info}
          tooltip={readOnlyPrice}
        />);
    return (
      <UI.ValidatedInput
        onChange={value => {
          if (typeof value == "undefined")
            value = "";
          var manualPrice = this.parseFormatted(value);
          
          this.multiSetPriceStep(cellInfo.original.lineId, PriceLine.ManualPrice, manualPrice);
        }}
        value={this.formatNumber(manualPrice, 2)}
      />
    );
  }

  renderDateTimePicker = (cellInfo: CellInfo) => {
    const { UI } = this.props;
    return(
      <UI.DateInput 
        key="input"
        value={this.state.lines[cellInfo.index][cellInfo.column.id]}
        onChange={(value: string) => {
          if (typeof value == "undefined")
            value = "";
          this.multiSetProperty(cellInfo.original.lineId, cellInfo.column.id, value);
        }}
      />
    );
  }

  renderEditableProperty = (cellInfo: CellInfo) => {
    const { UI } = this.props;
    return (
      <UI.ValidatedInput
        key="input"
        rows={1}
        onChange={(value: string) => {
          if (typeof value == "undefined")
            value = "";

          this.multiSetProperty(cellInfo.original.lineId, cellInfo.column.id, value);
        }}
        value={this.state.lines[cellInfo.index][cellInfo.column.id]}
      />
    );
  }

  renderUpDown = (value: any, lineId: string, upDisabled: boolean, downDisabled: boolean) => {
    const { icons } = this.props;
    return (
      <div style={{position: "relative"}}>
        <div style={{paddingRight: "16px"}}>{value}</div>
        <div style={{position: "absolute", right: "0", top: "0"}}>
          <div 
            title="Move Up"
            className={"upDownButton " + (upDisabled ? "disabled" : "enabled")}
            onClick={(e) => {
              if (upDisabled)
                return false;
              console.log("up");
              this.multiSetMoveLine(lineId, true);
            }}
          >
            <svg 
              viewBox="6 6 12 12" 
              dangerouslySetInnerHTML={{__html: icons["up-open"].body}}></svg>
          </div>
          <div 
            title="Move Down"
            className={"upDownButton " + (downDisabled ? "disabled" : "enabled")}
            onClick={(e) => {
              if (downDisabled)
                return false;
              console.log("down");
              this.multiSetMoveLine(lineId, false);
            }}
          >
            <svg 
              viewBox="6 6 12 12" 
              dangerouslySetInnerHTML={{__html: icons["down-open"].body}}></svg>
          </div>
        </div>
      </div>
    );
  }

  renderLineNumber = (cellInfo: CellInfo) => {
    const { loadingNewSequence } = this.state;
    let upDisabled = loadingNewSequence || cellInfo.index == 0 || this.state.lines[cellInfo.index].parentId != this.state.lines[cellInfo.index - 1].parentId;
    let downDisabled = loadingNewSequence || cellInfo.index == this.state.lines.length - 1 || this.state.lines[cellInfo.index].parentId != this.state.lines[cellInfo.index + 1].parentId;
    return this.renderUpDown(this.state.lines[cellInfo.index][cellInfo.column.id], cellInfo.original.lineId, upDisabled, downDisabled);
  }

  renderReadonlyProperty = (cellInfo: CellInfo) => {
    return (
      <span>{this.state.lines[cellInfo.index][cellInfo.column.id]}</span>
    );
  }

  handleGroupSelection = (groupSelectedState: boolean, groupId: string) => {
    const lines = [...this.state.lines];
    const currentRecords = this.getFilteredRows();
    lines.filter(p => p.parentId === groupId).forEach((line) => {
      line.selected = groupSelectedState && currentRecords.some(r => r.lineId == line.lineId);
    });
    this.setState({ lines });
  }

  handleSelectAll = (checked: boolean) => {
    const currentRecords = this.getFilteredRows();
    
    const lines = [...this.state.lines];
    lines.forEach(line => {
      line.selected = currentRecords.some(r => r.lineId == line.lineId) ? checked : !checked;
    });
    this.setState({ lines });
  }

  handleSelectLine = (index: number, selected: boolean) => {
    const lines = [...this.state.lines];
    lines[index].selected = selected;
    this.setState({ lines });
    //this.state.lines[index].selected = selected;
  }

  addHeading = () => {
    this.multiEditRequest("AddHeader", [], "New Group")
  }

  addProduct = (materialName: string) => {
    //TODO
    /*const data = [...this.state.data];

    var promise = this.props.actions.quote.addMaterial(materialName, this.props.quote, this.state.newProductGroup);

    var tempdata = [];
    return promise.then((res) => {
      tempdata = res.lines.filter(l => l.modelLineType !== 2);
      return tempdata;  
    }).then((tempdata) => {
      tempdata.map((line, index) => {

        if (this.state.data.filter(l => l.lineId === line.lineId).length === 0) {
          //TO DO: make a dummy action to get the netValue returned from the server before proceeding
          this.setLineDefaultFields(line);
          data.push(line); //TO DO: insert at correct position
        }
      });
      this.setState({ data });
    });*/
  }

  getProductSearchUrl = (queryString: string, salesArea: Quote.SalesArea): string => {
    return '/api/products/?query=' + queryString + 
            '&page=0&pageSize=6&configurable=false&language=' + salesArea.language + 
            '&salesOrganization=' + salesArea.salesOrganization + 
            '&distributionChannel=' + salesArea.distributionChannel + '&soldTo=null&shipTo=null';
  }

  searchProducts = (queryString: string) => {
    var tempproductList = [];

    return this.props.axios(this.getProductSearchUrl(queryString, this.props.quote.salesArea)).then((response) => {
      response.data.items.map((e) => {
        if (tempproductList.filter(t => t.externalId === e.externalId).length === 0) {
          tempproductList.push(e);
        }
      });
      this.setState({productList: tempproductList});
    });
  }

  setTotal = () => {
    const { spreadAmount } = this.state;
    const selected = this.getSelectedLines();
    
    let linesTotal = selected.reduce((a, b) => a + b.amount, 0)

    if (spreadAmount > linesTotal || spreadAmount < 0) {
      return;
    }

    this.multiEditRequest("SetTotal", this.getSelectedLineIds(), spreadAmount);
  }

  renderCheckbox = (cellInfo: CellInfo) => {
    return (
      <input
        type="checkbox"
        className="checkbox"
        checked={cellInfo.value}
        onChange={e => this.multiSetProperty(cellInfo.original.lineId, cellInfo.column.id, e.target.checked)}
      />);
  }

  renderAction = (cellInfo: CellInfo) => {
    const { UI, icons } = this.props;
    if (this.state.loadingNewSequence) {
      return (<UI.Icon
        color="lightgray"
        icon={icons.trash}
        tooltip="Delete"
      />);
    }
    return (
      <UI.IconButton
        onClick={e => {
          this.multiDeleteLines(cellInfo.original.lineId);
        }}
        icon={icons.trash}
        tooltip="Delete"
      />
    );
  }

  renderMessages = () => {
    const { UI, icons } = this.props;
    if (this.state.messages.length == 0)
      return <span/>
    return <div>
      <section>
        <div role="alert" className="center-block" style={{padding: '15px', borderWidth: '1px', marginBottom: '20px', borderStyle: 'solid', color: 'rgb(51, 51, 51)', backgroundColor: 'rgb(255, 255, 255)', width: '100%', borderColor: 'rgb(255, 195, 0)'}}>
          <span>
            <div className="media">
              <div className="media-left">
                <UI.Icon 
                  icon={icons.info} 
                  color={"warning"}
                />
              </div>
              <div className="media-body" data-test-id="quoteDetailsPanel-CustomMessage">
                {this.state.messages.map((message) => <div>{message}</div>)}
              </div>
            </div>
          </span>
        </div>
      </section>
    </div>;
  }

  columns = [ 
    {
      Header: () => (
        <input
          type="checkbox"
          onChange={(e) => this.handleSelectAll(e.target.checked)}
          checked={this.selectTable === null ? false : !this.getFilteredRows().some(l => !l.selected)}
        />
      ),
      headerClassName: 'text-left',
      accessor: 'selected',
      sortable:false,
      width: 25,
      filterable: false,
      Cell: (cellInfo) => (
        <input
          type="checkbox"
          className="checkbox"
          key={cellInfo.index}
          checked={cellInfo.original.selected}
          onChange={(e) => this.handleSelectLine(cellInfo.index, e.target.checked)}
        />
      )
    },         
    {
      Header: 'Group',
      width: 110,
      headerClassName: 'text-left',
      id: 'parentId',
      accessor: 'parentId',
      Cell: (cellInfo) => {
        const { UI } = this.props;
        var options = [...this.state.groups];
        var line = this.state.lines[cellInfo.index];
        if (line.parentId === null)
          options.push({description: "", id: null});
        //console.log("render group dropdown", line);
        if (this.state.loadingNewSequence) {
          let group = options.find(g => g.id == line.parentId);
          return <span className="imitateDropdown">{group == null ? "" : group.description}</span>;
        }
        return (
          <select onChange={(e) => this.multiSetParentId(line.lineId, e.target.value)}>
            {options.map(e =>
              <option selected={line.parentId === e.id} value={e.id}>{e.description}</option>
            )}
          </select>
        );
      },
      Footer: (cellInfo) => {
        const { UI } = this.props;
        return ([
          <UI.Badge>{"Selected Total"}</UI.Badge>,
          <br/>,
          <UI.Badge>{"Quote Total"}</UI.Badge>
        ]);
      }
    },
    {
      Header: '#',
      width: 56,
      headerClassName: 'text-right',
      className: 'text-right',
      id: 'lineNumber',
      accessor: 'lineNumber',
      Cell: this.renderLineNumber
    },
    {
      Header: 'Product',
      headerClassName: 'text-left',
      id:'material',
      accessor: 'material',
    },
    {
      Header: 'Long Description',
      id: 'longDescription',
      width: 300,
      headerClassName: 'text-left',
      accessor: "longDescription",
      Cell: this.renderEditableProperty
    },
    {
      Header: 'Qty',
      id: "quantity",
      width: 45,
      headerClassName: 'text-right',
      className: 'text-right',
      accessor: 'quantity',
      Cell: this.renderEditableQuantity,
      Footer: (cellInfo) => {
        const { UI } = this.props;
        var filtdata = this.state.lines.filter(l => l.selected);
        return ([
          <UI.Badge>{this.formatNumber(filtdata.reduce((a, b) => a + b.quantity, 0), 0)}</UI.Badge>,
          <br/>,
          <UI.Badge>{this.formatNumber(this.state.lines.reduce((a, b) => a + b.quantity, 0), 0)}</UI.Badge>
        ]);
      }
    },
    {
      Header: 'List Price',
      id: 'listPrice',
      width: 80,
      headerClassName: 'text-right',
      className: 'text-right',
      accessor: d => this.formatNumber(d.listPrice, 2)
    },
    {
      Header:'Price',
      columns:[
        {
          Header: 'Manual Price',
          id:'manualPrice',
          width: 80,
          headerClassName: 'text-right',
          className: 'text-right',
          accessor: "manualPrice",
          Cell: this.renderEditableManualPrice
        },
        {
          Header: 'Amount',
          id: 'amount',
          width: 80,
          headerClassName: 'text-right',
          className: 'text-right',
          accessor: d => this.formatNumber(d.amount),
          filterable: false,
          Footer:(cellInfo) => {
            const { UI } = this.props;
            var filtdata = this.state.lines.filter(l => l.selected);
            return ([
              <UI.Badge>{this.formatNumber(filtdata.reduce((a, b) => a + b.amount, 0), 2)}</UI.Badge>,
              <br/>,
              <UI.Badge>{this.formatNumber(this.state.lines.reduce((a, b) => a + b.amount, 0), 2)}</UI.Badge>
            ]);
          }
        },
        {
          Header: 'Discount',
          width: 60,
          id: 'discount',
          headerClassName: 'text-right',
          className: 'text-right',
          accessor: "discount",
          Cell: this.renderEditableDiscount
        },
        {
          Header: 'Method',
          width: 75,
          headerClassName: 'text-left',
          id: 'discountMethod',
          accessor: 'discountMethod',
          filterable: false,
          Cell: (cellInfo) => (
            <select id={"DMethod"+cellInfo.index} onChange={(e) => this.multiSetPriceStep(cellInfo.original.lineId, e.target.value)}>
              <option selected={cellInfo.value==PriceLine.DiscountPercent} value={PriceLine.DiscountPercent}>%</option>
              <option selected={cellInfo.value==PriceLine.DiscountAmount} value={PriceLine.DiscountAmount}>Amount</option>
            </select>
          )
        },
        {
          Header: 'Type',
          headerClassName: 'text-left',
          id: "discountType",
          accessor: 'discountType',
          getProps: (state, rowInfo) => {
            if (typeof rowInfo == "undefined")
              return {};
            const { discount, discountType } = rowInfo.original;
            
            return {
              style: {
                border: rowInfo && discount != 0 && discount != null && (discountType == null || discountType == "") ? '3px solid red' : null,
              },
            };
          },
          Cell: (cellInfo) => {
            var currentValue = cellInfo.original.discountType;
            return (
              <select onChange={(e) => this.multiSetProperty(cellInfo.original.lineId, cellInfo.column.id, e.target.value)}>
                <option value=""></option>
                <option selected={currentValue==="Campaign"} value="Campaign">Campaign</option>
                <option selected={currentValue==="Commission"} value="Commission">Commission</option>
                <option selected={currentValue==="Competition"} value="Competition">Competition</option>
                <option selected={currentValue==="Demo"} value="Demo">Demo</option>
                <option selected={currentValue==="Education"} value="Education">Education</option>
                <option selected={currentValue==="Key Account"} value="Key Account">Key Account</option>
                <option selected={currentValue==="Minor Repair"} value="Minor Repair">Minor Repair</option>
                <option selected={currentValue==="Other"} value="Other">Other</option>
                <option selected={currentValue==="Replacement"} value="Replacement">Replacement</option>
                <option selected={currentValue==="Service"} value="Service">Service</option>
                <option selected={currentValue==="System"} value="System">System</option>
                <option selected={currentValue==="Volume"} value="Volume">Volume</option>
                <option selected={currentValue==="Warranty Replacement"} value="Warranty Replacement">Warranty Replacement</option>
              </select>
            );
          }
        }
      ]
    },
    {
      Header: 'Ext. Amount',
      id: 'extendedAmount',
      width: 80,
      headerClassName: 'text-right',
      className: 'text-right',
      accessor: d => this.formatNumber(d.extendedAmount, 2),
      Footer: (cellInfo) => {
        const { UI } = this.props;
        var filtdata = this.state.lines.filter(l => l.selected);
        return ([
          <UI.Badge>{this.formatNumber(filtdata.reduce((a, b) => a + b.extendedAmount, 0), 2)}</UI.Badge>,
          <br/>,
          <UI.Badge>{this.formatNumber(this.state.lines.reduce((a, b) => a + b.extendedAmount, 0), 2)}</UI.Badge>
        ]);
      }
    },
    {
      Header: 'Comment',
      id: 'comment',
      width: 200,
      headerClassName: 'text-left',
      accessor: "comment",
      Cell: this.renderEditableProperty
    },
    {
      Header: 'Bill',
      id: "billCreditFlag",
      width: 30,
      headerClassName: 'text-left',
      accessor: "billCreditFlag",
      filterable: false,
      Cell: this.renderCheckbox
    },
    {
      Header: 'Ship',
      id: "shipReceiveFlag",
      width: 30,
      headerClassName: 'text-left',
      accessor: "shipReceiveFlag",
      filterable: false,
      Cell: this.renderCheckbox
    },
    {
      Header: 'WH',
      id: 'warehouse',
      width: 55,
      headerClassName: 'text-left',
      accessor: "warehouse",
      Cell: (cellInfo) => {
        var currentValue = cellInfo.original.warehouse;
        return (
          <select id="WH" onChange={(e) => this.multiSetProperty(cellInfo.original.lineId, cellInfo.column.id, e.target.value)}>
            <option value=""></option>
            <option selected={currentValue==="ESP"} value="ESP">ESP</option>
            <option selected={currentValue==="AUS"} value="AUS">AUS</option>
            <option selected={currentValue==="HK"} value="HK">HK</option>
            <option selected={currentValue==="CN"} value="CN">CN</option>
            <option selected={currentValue==="PL"} value="PL">PL</option>
            <option selected={currentValue==="PRD"} value="PRD">PRD</option>
            <option selected={currentValue==="GB"} value="GB">GB</option>
            <option selected={currentValue==="DE"} value="DE">DE</option>
            <option selected={currentValue==="AT"} value="AT">AT</option>
            <option selected={currentValue==="SG"} value="SG">SG</option>
            <option selected={currentValue==="US"} value="US">US</option>
            <option selected={currentValue==="CA"} value="CA">CA</option>
            <option selected={currentValue==="FR"} value="FR">FR</option>
            <option selected={currentValue==="JP"} value="JP">JP</option>
            <option selected={currentValue==="NL"} value="NL">NL</option>
            <option selected={currentValue==="IT"} value="IT">IT</option>
            <option selected={currentValue==="TW"} value="TW">TW</option>
            <option selected={currentValue==="KR"} value="KR">KR</option>
            <option selected={currentValue==="VTS"} value="VTS">VTS</option>
            <option selected={currentValue==="BR"} value="BR">BR</option>
            <option selected={currentValue==="GEO"} value="GEO">GEO</option>
          </select>
        );
      }
    },
    {
      Header: 'Request Date',
      headerClassName: 'text-left',
      width: 100,
      style: {overflow: 'visible'},
      id: "requestedDeliveryDate",
      accessor: "requestedDeliveryDate",
      Cell: this.renderDateTimePicker
    },
    {
      Header: 'System',
      headerClassName: 'text-left',
      id: "system",
      accessor: "system",
      Cell: this.renderEditableProperty
    },
    {
      Header: 'Contract',
      id: 'contract',
      headerClassName: 'text-left',
      accessor: "contract",
      Cell: this.renderReadonlyProperty
    },
    {
      Header: 'Reason',
      id: 'reasonCode',
      headerClassName: 'text-left',
      accessor: "reasonCode",
      getProps: (state, rowInfo) => {
        return {
          style: {
            border : rowInfo && rowInfo.row.quantity < 1 && rowInfo.row.reasonCode === "" ? '3px solid red' : null,
          },
        };
      },
      Cell: (cellInfo) => {
        var currentValue = cellInfo.original.reasonCode;
        return (
          <select onChange={(e) => this.multiSetProperty(cellInfo.original.lineId, cellInfo.column.id, e.target.value)} style={{width: "100%"}}>
            <option value=""></option>
            <option selected={currentValue==="COMBUSS"} value="COMBUSS">COMBUSS</option>
            <option selected={currentValue==="CREDIT and REBILL"} value="CREDIT and REBILL">CREDIT and REBILL</option>
            <option selected={currentValue==="DUPLICATE BILLING"} value="DUPLICATE BILLING">DUPLICATE BILLING</option>
            <option selected={currentValue==="TRADE-IN"} value="TRADE-IN">TRADE-IN</option>
            <option selected={currentValue==="WRONG ORDERED"} value="WRONG ORDERED">WRONG ORDERED</option>
            <option selected={currentValue==="WRONG PRICE"} value="WRONG PRICE">WRONG PRICE</option>
            <option selected={currentValue==="WRONG PRODUCT"} value="WRONG PRODUCT">WRONG PRODUCT</option>
            <option selected={currentValue==="Warrep"} value="Warrep">Warrep</option>
            <option selected={currentValue==="UPGRADE SOFTWARE"} value="UPGRADE SOFTWARE">UPGRADE SOFTWARE</option>
          </select>
        );
      }
    },
    {
      Header: 'Key/Serial',
      id: 'serial',
      headerClassName: 'text-left',
      accessor: "serial",
      Cell: this.renderEditableProperty
    },
    {
      Header: 'Actions',
      headerClassName: 'text-left',
      filterable: false,
      width: 60,
      Cell: this.renderAction
    }
  ] as Array<Column<CustomLine>>;

  render = () => {
    const { UI, icons } = this.props;
    const styles = {
      appContainer: {
        border: '1px solid #ccc'
      } as React.CSSProperties,
      topMenu: {
        borderBottom: '1px solid #ccc',
        backgroundColor: '#efefef'
      } as React.CSSProperties,
      sideNavContainer: {
        position: 'relative',
        width: '100%',
        height: '100%',
        zIndex: 200,
        display: 'flex'
      } as React.CSSProperties,
      sideNavContent: {
        flex: 1,
        padding: '0 0px',
        overflow: 'auto',
      } as React.CSSProperties
    };

    return (
      <div className="multiEdit">
        {this.renderMessages()}
        <div>
          <section style={styles.appContainer}>
            <nav style={styles.topMenu}>
              <UI.IconButton
                icon={icons.menu}
                tooltip="Toggle side bar"
                onClick={() => {
                  this.setState({expanded: !this.state.expanded});
                }} 
              />
              <UI.IconButton
                style={{marginLeft:'95%'}}
                icon={icons.download}
                tooltip="Upload to CRM"
                onClick={this.handleOpen} 
              />
            </nav>
            <div style={styles.sideNavContainer}>
              <UI.SideNav
                expandedWidth="250px"
                constrain={true}
                appendBackdropToParent={true}
                expanded={this.state.expanded}
                push={'Pushed'}
                overlayed={'Pushed'}
                requestCollapse={() => this.setState( { expanded: false } )}
              >
                <div style={{height:'100%'}}>
                  <header style={{  padding: '10px 10px' }}>
                    <h3>Functions</h3>
                  </header>
                  <UI.SideNavMenu bordered={false}>
                    <UI.MenuItem isHeader={true}>Set Total (selected lines)</UI.MenuItem>
                    <div style={{padding: "24px"}}>
                      <div style={{width:'100%', display:'flex'}}>
                        <UI.ValidatedInput 
                          onChange={(value: string) => { 
                            this.setState({ spreadAmount: this.parseFormatted(value) }); 
                          }}
                          validate={(value: string) => {
                            let val = this.parseFormatted(value)
                            let totalWithoutDiscount = this.getSelectedLines().reduce((a, b) => a + b.amount, 0)

                            if (value === undefined || val < totalWithoutDiscount)
                              return true;
                          }}
                          getHelpText={( errorCode ) => {
                            return (errorCode !== undefined ? 'Invalid Input' :null);
                          }}
                        />
                        <UI.IconButton 
                          style={{marginLeft:10}} 
                          size="large" 
                          icon={icons.import} 
                          tooltip="Apply"  
                          onClick={this.setTotal} 
                        />
                      </div>
                      
                      <br/>
                    </div>
                    <UI.MenuItem isHeader={true}>
                      Edit Groups
                      <UI.IconButton
                        size="large" 
                        icon={icons.plus} 
                        tooltip="Add Group"
                        onClick={this.addHeading} 
                      />
                    </UI.MenuItem>
                    <div>
                      <div style={{padding: "24px"}}>
                        {this.state.groups.map((group, index) => { 
                          return ([
                            <div style={{width:'100%',display:'flex'}}>
                              <UI.CheckBox
                                style={{
                                  transform: 'translateY(-25%)'
                                }}
                                label=""
                                value={group.id}
                                /*checked={this.state.checkBoxesGroup.filter(s => s.lineId === name.lineId).status}*/
                                onChange={(e) => this.handleGroupSelection(e.target.checked, e.target.value)}
                                />
                              <UI.ValidatedInput 
                                width={'100%'} 
                                onChange={(value) => this.multiSetDescription(group.id, value)}
                                value={group.description}
                              />
                              {this.renderUpDown("", group.id, this.state.loadingNewSequence || index == 0, this.state.loadingNewSequence || index == this.state.groups.length - 1)}
                              <UI.IconButton
                                onClick={e => {
                                  this.multiDeleteLines(group.id);
                                }}
                                icon={icons.trash}
                                tooltip="Delete"
                              />
                            </div>
                          ]);  
                        })}
                      </div>
                      <br/>
                    </div>
                    <UI.MenuItem isHeader={true} style={{display: "none"}}>Add Product</UI.MenuItem>
                    <div style={{height:'30em', display: "none"}}>
                      <div>
                        <small style={{marginLeft:24}}>Group</small>
                        <select style={{marginLeft:24, width:'45%'}} onChange={(e) => { this.setState({newProductGroup: e.target.value}); }} id="productGroup">
                          {this.state.groups.map((e, i) =>
                            <option value={e.id}>{e.description}</option>
                          )}
                        </select>
                        <div style={{marginLeft:24,width:'100%'}}>
                          <br/>
                          <UI.MultiSelect saveButtonText="ADD" style={{marginLeft:50, width:'100%'}} onSave={(e,v)=>{this.addProduct(this.state.newProduct); this.setState({newProduct:''});  }} onSelect={(e)=>{this.setState({newProduct: e});}} onSearch={(e) => {this.searchProducts(e)}} placeholder="Choose a Product">
                            <UI.Menu>
                              {this.state.productList.map((e)=>{
                                return(
                                  <UI.MenuItem value={e.externalId}>{e.externalId+' - '+e.description}</UI.MenuItem>
                                );
                              })}
                            </UI.Menu>
                          </UI.MultiSelect>
                        </div>
                      </div>
                      <br/>
                    </div>
                  </UI.SideNavMenu>
                </div>
              </UI.SideNav>
              <div style={styles.sideNavContent}>
                <section>
                  <div>
                    <ReactTable
                      data={this.state.lines}
                      ref={(r) => {
                        this.selectTable = r as Instance<CustomLine>;
                      }}
                      columns={this.columns}
                      noDataText="No Data Available"
                      loading={this.state.loading}
                      filterable
                      defaultFilterMethod={this.textFilter}
                      defaultPageSize={100}
                      minRows={12}
                      getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                            style: {
                              background: rowInfo.original.selected ? '#82CAFA' : 'white'
                            }
                          };
                        }
                        else
                          return {};
                      }}
                    />
                  </div>
                </section>
              </div>
              <UI.Modal
                maxHeight={400}
                maxWidth={600}
                isOpen={this.state.isOpen}
                onRequestClose={this.handleClose}
                ariaHideApp={false}>
                <UI.ModalHeader>
                  Send to CRM
                </UI.ModalHeader>
                <UI.ModalContent>
                  <CrmUpload  {...this.props} />
                </UI.ModalContent>
                <UI.ModalFooter>
                  <UI.Button flat onClick={() => this.handleClose()} type="primary">Close</UI.Button>
                </UI.ModalFooter>
              </UI.Modal>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default MultiEditUI;
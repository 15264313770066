export const EU: Quote.PriceBundle = {
  name: 'EU Pricing',
  roles: ['SpecificUser'],
  layer: 1,
  line: [{
    stepId: 'netValue',
    text: 'EU',
    primary: true,
    translationKey: 'Solution.Line.NetValue'
  }],
  quote: [{
    stepId: 'netValue',
    primary: false,
    text: 'Net Income'
  }]
};

export const ES: Quote.PriceBundle = {
  name: 'Spain Pricing',
  layer: 2,
  salesAreas: ['3100/01/01'],
  line: [{
    stepId: 'netValue',
    text: '',
    primary: true,
    translationKey: 'Solution.Line.NetValue'
  }]
};

const defaultPriceTableSettings: Quote.PriceTable = {
  steps: [
    '011.00',
    '100.00',
    { id: '110.02', title: 'Discount (%)' },
    '110.03',
    '110.04',
    '300.00',
    '400.00',
    '800.00',
    '810.01',
    '810.02',
    '890.00'
  ],
  summaryLines: [{
    step: '800.00',
    title: 'Total Net Value for Item',
    translationKey: 'Quotes.Price.TotalNetValue'
  }, {
    step: '810.01',
    title: 'Quote Percentage Discount',
    translationKey: 'Quotes.Price.PercentageDiscount',
    fromQuote: true
  }, {
    step: '810.02',
    title: 'Quote Discount',
    translationKey: 'Quotes.Price.Discount',
    fromQuote: true
  }, {
    step: '920.00',
    title: 'Quote Total Price',
    translationKey: 'Quotes.Price.TotalPrice',
    fromQuote: true
  }],
  showExtendedPrice: true
};
export const Total: Quote.PriceBundle = {
  name: 'Net Price',
  // Optional, Bundles can be stacked in layers, those that matches the user role
  // and Sales Area criteria will be merged down,
  // from highest to lowest layer (0). 0 is default if not specified.
  layer: 0,
  // Optional, a list of roles can be defined for use with this bundle
  // roles: ['SomeRole', 'SomeOtherRole'],
  // Optional, a list of one or more Sales Areas:
  // salesAreas:  [ 'xxxx/yy/zz', ... ],  (where xxxx is salesOrganization,
  // yy is distributionChannel and zz is salesDivision)
  line: [{
    stepId: 'netValue',
    text: '',
    primary: true,
    translationKey: 'Solution.Line.NetValue'
  }],
  quote: [{
    stepId: 'netValue',
    primary: false,
    text: 'Net Value',
    translationKey: 'Solution.Quote.NetValue'
  }, {
    stepId: 'taxValue',
    primary: false,
    text: 'Tax',
    translationKey: 'Solution.Quote.Tax'
  }, {
    stepId: 'totalPrice',
    primary: true,
    text: 'Total Price',
    translationKey: 'Solution.Quote.TotalPrice'
  }],
  quoteListItem: [{
    stepId: 'totalPrice',
    primary: true,
    text: ''
  }],
  priceTable: defaultPriceTableSettings
};

export const Search: Quote.SingleLineDefinitions = [{
  colSpan: 5,
  align: 'left',
  textOverflow: true,
  property: 'name'
}, {
  colSpan: 7,
  align: 'right',
  textOverflow: true,
  property: 'description'
}];

export const Catalog: Quote.SingleLineDefinitions = [{
  property: ['name']
}, {
  defaultValue: ' — '
}, {
  property: 'description',
  defaultValue: 'No product description',
  translationKey: 'Quotes.Product.DefaultValue'
}];

export const AdvancedPricing: Quote.SingleLineDefinitions = [{
  property: [{ primary: 'variantCode', alternatives: ['materialVariant.code'] }, 'description'],
  separator: ' '
}];

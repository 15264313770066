export const RevisionLineDefinition: Quote.RevisionLineDefinition = {
  revisionList: [
    [ {
      colSpan: 1,
      property: 'revision',
      align: 'left',
      small: false,
      textOverflow: false
    }, {
      colSpan: 2,
      property: 'quickRef',
      align: 'left',
      small: true,
      textOverflow: false
    }, {
      colSpan: 9,
      property: 'title',
      align: 'left',
      small: false,
      textOverflow: true
    }, {
      colSpan: 7,
      property: 'ownerUserName',
      align: 'left',
      small: false,
      textOverflow: true
    }, {
      colSpan: 4,
      property: 'totalNetValue',
      align: 'right',
      small: false,
      textOverflow: true
    } ]
  ]
};

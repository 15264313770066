export const CustomLine: Quote.SingleLineDefinitions = [{
  colSpan: 3,
  property: 'name'
}, {
  colSpan: 4,
  property: ['description'],
  separator: ' '
}, {
  colSpan: 2,
  align: 'right',
  property: ['attachment']
}, {
  colSpan: 3,
  align: 'right',
  property: 'price'
}];

export const CopyCustomLine: Quote.SingleLineDefinitions = [{
  colSpan: 3,
  property: 'name'
}, {
  colSpan: 4,
  property: 'description',
  separator: ' '
}, {
  colSpan: 2,
  align: 'right',
  property: 'attachment'
}, {
  colSpan: 3,
  align: 'right',
  property: 'price'
}];

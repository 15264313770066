const filters: Quote.Filter[] = [
  {
    field: 'CreatedDate',
    translationKey: 'QuoteSearchFilters.CreatedDate.Label',
    type: 'Date',
    source: {
      type: 'list',
      list: [ {
        days: 0,
        text: 'Today',
        translationKey: 'QuoteSearchFilters.Date.Range.Today'
      }, {
        days: -7,
        text: 'Last 7 days',
        translationKey: 'QuoteSearchFilters.Date.Range.Last7Days'
      }, {
        days: -30,
        text: 'Last 30 days',
        translationKey: 'QuoteSearchFilters.Date.Range.Last30Days'
      } ]
    }
  }, {
    field: 'ExpirationDate',
    translationKey: 'QuoteSearchFilters.ExpirationDate.Label',
    type: 'Date',
    source: {
      type: 'list',
      list: [ {
        days: 0,
        text: 'Today',
        translationKey: 'QuoteSearchFilters.Date.Range.Today'
      }, {
        days: 7,
        text: 'Next 7 days',
        translationKey: 'QuoteSearchFilters.Date.Range.Next7Days'
      }, {
        days: 30,
        text: 'Next 30 days',
        translationKey: 'QuoteSearchFilters.Date.Range.Next30Days'
      } ]
    },
    salesAreas: ['CPB1/CB/00']
  }, {
    field: 'ApprovalStatus',
    translationKey: 'QuoteSearchFilters.ApprovalStatus.Label',
    type: 'MultiSelect',
    source: {
      type: 'list',
      list: [ {
        value: '0',
        text: 'Not Started',
        translationKey: 'Approvals.Status.NotStarted'
      }, {
        value: '1',
        text: 'Pending',
        translationKey: 'Approvals.Status.Pending'
      }, {
        value: '2',
        text: 'Approved',
        translationKey: 'Approvals.Status.Approved'
      }, {
        value: '3',
        text: 'Rejected',
        translationKey: 'Approvals.Status.Rejected'
      }, {
        value: '4',
        text: 'Cancelled',
        translationKey: 'Approvals.Status.Cancelled'
      }, {
        value: '5',
        text: 'Not Needed',
        translationKey: 'Approvals.Status.NotNeeded'
      } ]
    }
  }, {
    field: 'Status',
    translationKey: 'QuoteSearchFilters.Status.Label',
    type: 'Select',
    source: {
      type: 'query',
      query: '\n        query{\n          resources{\n            status{\n              source: all{\n                value\n                text\n                translationKey: value\n              }\n            }\n          }\n        }',
      translationKeyPrefix: 'Property.Quote.Status'
    }
  }, {
    field: 'OwnerUserName',
    translationKey: 'QuoteSearchFilters.CreatedBy.Label',
    type: 'MultiSelect',
    source: {
      type: 'query',
      query: '\n        query ($search: String!) {\n          users {\n            pagedSearch(querystring: $search, take: 50, skip: 0) {\n              source: users {\n                value: initials\n                text: name\n              }\n              hasMore: hasMoreResults\n            }\n          }\n        }',
      displayFormat: function displayFormat( value, text ) {
        return value + ' - ' + text;
      },
      variables: { search: '' }
    }
  }, {
    field: 'SalesDistrict',
    translationKey: 'QuoteSearchFilters.SalesDistricts.Label',
    type: 'MultiSelect',
    source: {
      type: 'query',
      query: '\n      query ($search: String, $page: Int!, $perPage: Int!) {\n        permissions {\n          salesDistricts(filter: $search, paging: {page: $page, perPage: $perPage}) {\n            hasMore\n            source: items {\n              value\n              text\n            }\n          }\n        }\n      }',
      variables: { search: '', page: 0, perPage: 25 }
    }
  }, {
    field: 'SoldToCompanyName',
    translationKey: 'QuoteSearchFilters.SoldToCompanyName.Label',
    type: 'Select',
    source: {
      type: 'query',
      query: '\n      query ($search: String) {\n        permissions {\n          partners(filter: $search, paging: {page: 0, perPage: 25}) {\n            hasMore\n            source: items {\n              value: companyName\n              text: externalId\n            }\n          }\n        }\n      }',
      displayFormat: function displayFormat( value, text ) {
        return text + ' - ' + value;
      },
      variables: { search: '' }
    }
  }, {
    field: 'SalesArea',
    translationKey: 'QuoteSearchFilters.SalesArea.Label',
    type: 'Select',
    source: {
      type: 'query',
      query: '\n      query ($search: String) {\n        permissions {\n          salesAreas(filter: $search, paging: {page: 0, perPage: 25}, permissionValue: CAN_READ) {\n            hasMore\n            source: items {\n              value: name\n              text: id\n            }\n          }\n        }\n      }',
      variables: { search: '' },
      displayFormat: function displayFormat( value, text ) {
        return value + ' - ' + text;
      }
    }
  }, {
    field: 'CustomProp_Market',
    translationKey: 'QuoteSearchFilters.CustomProp.Market.Label',
    type: 'TextBox',
    comparisonType: 'StartsWith'
  }
];

export default filters;

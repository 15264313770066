import * as React from 'react';
import MultiEditUI from './multiEditUi';
const MultiEdit: Quote.IComponentPlugin = { 
  icon: "template",
  id: "multi-edit",
  title: "Edit Lines", 
  position: 4, 
  isMenuItem: false, 
  show: (e) => { return !e.quote.locked},
  component: (lib) => {
    class MyPlugin extends lib.React.Component { 
      render() {
        return (
          <MultiEditUI 
            {...lib}
            {...this.props}
          />
        );
      }
    };
    return MyPlugin;
  }
};

export default MultiEdit;

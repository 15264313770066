const quoteDetailsDefinitions: Quote.QuoteDetailsDefinition = {
  infoPanel: {
    maxSize: 320,
    detailsPanel: {
      container: {
        height: 200
      }
    }
  }
};

export default quoteDetailsDefinitions;

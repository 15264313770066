import * as React from 'react';

import CrmUpload from './crm-upload';

const CrmUploadPlugin: Quote.IComponentPlugin = {
  icon: 'download',
  id: 'crm-upload-quote',
  title: 'Send to CRM',
  isMenuItem: false,
  show: (e) => true,
  component: ( lib ) => {
    class Holder extends lib.React.Component {
      render() {
        return (
          <CrmUpload
            {...lib}
            {...this.props} />
        );
      }
    }

    return Holder;
  }
};

export default CrmUploadPlugin;

const approvalCheckSettings: Quote.ApprovalSettings = {
  priceTableScreen: {
    visible: false
  },
  normalPriceScreen: {
    visible: false
  },
  configuratorScreen: {
    visible: false
  }
};
export default approvalCheckSettings;

const configurationSettings: Quote.ConfigurationSetttings = {
  infoPanel: {
    variable: {
      panel: {
        content: {
          maxHeight: 400,
          maxLines: 5,
          properties: ['image', 'text', 'info']
        }
      },
      dialog: {
        title: [
          {
            type: 'property',
            name: 'text'
          }
        ],
        subTitle: [
          {
            type: 'text',
            en: 'Variable',
            da: 'Variabel'
          }
        ],
        content: [
          {
            type: 'property',
            name: 'image',
            flex: '0 1 130px'
          }, {
            type: 'property',
            name: 'info',
            flex: '1 1 200px'
          }
        ]
      }
    },
    value: {
      panel: {
        content: {
          maxHeight: 400,
          maxLines: 5,
          properties: ['image', 'text', 'name', 'info']
        }
      },
      dialog: {
        title: [
          {
            type: 'property',
            name: 'variable'
          }, {
            type: 'text',
            en: ': '
          }, {
            type: 'property',
            name: 'text'
          }
        ],
        subTitle: [
          {
            type: 'property',
            name: 'name'
          }
        ],
        content: [
          {
            type: 'property',
            name: 'image',
            flex: '0 1 130px'
          }, {
            type: 'property',
            name: 'info',
            flex: '1 1 200px'
          }
        ]
      }
    },
    group: {
      panel: {
        content: {
          maxHeight: 400,
          maxLines: 5,
          properties: ['image', 'fullyQualifiedName', 'name', 'info']
        }
      },
      dialog: {
        title: [
          {
            type: 'property',
            name: 'text'
          }
        ],
        subTitle: [
          {
            type: 'text',
            en: 'Group',
            da: 'Gruppe'
          }
        ],
        content: [
          {
            type: 'property',
            name: 'image',
            flex: '0 1 130px'
          }, {
            type: 'property',
            name: 'info',
            flex: '1 1 200px'
          }
        ]
      }
    }
  },
  showVariableLinks: true,
  splitPaneDefinition: {
    leftPanel: {
      maxSize: 315
    },
    rightPane: {
      maxSize: 615
    }
  },
};

export default configurationSettings;

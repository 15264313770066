export const QuoteLine: Quote.MultiLineDefinitions = [[{
  colSpan: 4,
  property: 'title',
  textOverflow: true,
  heading: {
    text: '${groupHeading}',
    strong: true,
    showInAllGroups: true
  }
}, {
  colSpan: 2,
  property: 'owner.name',
  heading: {
    text: 'Owner',
    translationKey: 'Solution.QuoteHeader.Owner'
  }
}, {
  colSpan: 2,
  property: 'modifier.name',
  heading: {
    text: 'Last Modifier'
  }
}, {
  colSpan: 2,
  property: 'expirationDate',
  options: {
    format: 'short'
  },
  heading: {
    text: 'Expiration'
  }
}, {
  colSpan: 2,
  align: 'right',
  property: 'price'
}], [{
  colSpan: 4,
  textOverflow: true,
  muted: true,
  property: 'soldTo.partner'
}, {
  colSpan: 3,
  textOverflow: true,
  muted: true,
  property: 'salesArea.name'
}, {
  align: 'right',
  colSpan: 5,
  muted: true,
  small: true,
  property: ['crmOpportunityId', 'salesDocumentNumber', 'status'],
  separator: ' '
}]];

export const CopyQuoteLine: Quote.MultiLineDefinitions = [[{
  colSpan: 4,
  property: 'title',
  textOverflow: true
}, {
  colSpan: 2,
  property: 'ownerUserName'
}, {
  colSpan: 2,
  muted: true,
  small: true,
  property: 'salesDocumentNumber'
}, {
  colSpan: 1,
  muted: true,
  small: true,
  property: 'status'
}, {
  colSpan: 3,
  align: 'right',
  property: 'price'
}]];

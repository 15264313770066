const DisableTemplate = {
  show: ( cxt ) => true,
  enabled: ( cxt ) => false,
  title: ( cxt ) => 'Button disabled by administrator'
};
const HideTemplate = {
  show: ( ctx ) => false,
  enabled: ( ctx ) => false,
  title: ( ctx ) => 'Button hidden by administrator'
};

export const Disable = ( app: Quote.InternalAppType ): Quote.InternalAppSetting => {
  return Object.assign( {id: app}, DisableTemplate );
};

export const Hide = ( app: Quote.InternalAppType ): Quote.InternalAppSetting => {
  return Object.assign( {id: app}, HideTemplate );
};

export const HeaderLine: Quote.SingleLineDefinitions = [{
  colSpan: 7,//3,
  property: 'name',
}, /*{
  colSpan: 4,
  property: 'GroupDiscountText'
}, */{
  colSpan: 2,
  property: 'groupMaterialName'
}, {
  colSpan: 1,
  property: 'groupAsset'
}, {
  colSpan: 2,
  align: 'right',
  property: 'price'
}];

export const CopyHeaderLine: Quote.SingleLineDefinitions = [{
  colSpan: 7,
  property: 'name'
}, {
  colSpan: 2,
  align: 'right',
  property: ['headerConfiguration', 'checklist', 'attachment']
}, {
  colSpan: 3,
  align: 'right',
  property: 'price'
}];

import * as React from 'react';
import * as PropType from 'prop-types';

class CrmUpload extends React.Component {
  static propTypes = {
    UI: PropType.object.isRequired,
    axios: PropType.func.isRequired,
    quote: PropType.object.isRequired,
    actions: PropType.any,
    plugin: PropType.any,
    i18n: PropType.any
  };

  constructor( props ) {
    super( props );
    this.state = {
      isUploading: true,
      isComplete: false,
      documentId: props.quote.documentId,
      revision: props.quote.revision,
      scribeState: [],
      message: null,
      updatedLines: []
    };
    console.log("quote", props.quote);
  }
  
  componentDidMount = () => {
    const { axios, quote } = this.props;
    
    axios.post(window.systemSettings.baseUrl + 'api/CrmQuote/SaveToCrm?quoteId=' + this.state.documentId + '&revision=' + this.state.revision, {}, { withCredentials: true }).then((result) => {
      console.log("CRM Upload Result", result);
      let { updatedLines, lines, groups, error } = result.data;
      this.setState({
        isUploading: false,
        updatedLines: updatedLines,
        isComplete: error != null,
        message: result.data.error,
        groups: groups,
        lines: lines
      });
      if (result.data.error != null) {
        return; //error occurred
      }

      var checkScribeStatus = () => {
        axios.get(window.systemSettings.baseUrl + 'api/CrmQuote/GetQuoteStatus?cpqId=' + quote.quickRef, {}, { withCredentials: true }).then((result) => {
          console.log("status: ", result.data);
          var complete = true;
          for (var i = 0; i < result.data.length; i++) {
            var data = result.data[i];
            if (updatedLines.findIndex(u => u == data.id) != -1 && 
              (data.errorMessage === null || data.errorMessage === "" ||
              (data.errorMessage != "Success" && data.errorRepetition < 2))) { //products can fail because the group has not been created yet, but Scribe will retry, so check errorRepetition
              complete = false;
              break;
            }
          }
          if (!complete)
            setTimeout(checkScribeStatus, 2000);

          this.setState({
            isComplete: complete,
            scribeState: result.data
          });
        });
      };
      checkScribeStatus();
    });
  }

  render = () => {
    const { isUploading, isComplete, scribeState, message, groups, lines, updatedLines } = this.state;
    const { UI, icons } = this.props;

    if (isUploading) {
      return <div>Uploading quote to Scribe</div>;
    }
    if (message != null) {
      return <div>An error occurred while sending the quote to Scribe: <br /><span style={{color:"red"}}>{message}</span></div>;
    }
    var tc = {
      padding: "5px",
      textAlign: "center",
      borderTop: "1px solid lightgrey"
    };
    var cs = {
      padding: "5px"
    };
    var csb = {
      padding: "5px",
      borderRight: "1px solid lightgrey"
    };
    console.log("render", scribeState, updatedLines);
    return (
      <div>
        {!isComplete &&
          <div>Waiting for Scribe to process quote</div>
        }
        {scribeState.length > 0 &&
          <div>
            <div>Upload Status:</div>
            <table>
              <tr>
                <td colspan="3" style={tc}>Groups:</td>
              </tr>
              <tr>
                <th style={csb}>Group Number</th>
                <th style={csb}>Name</th>
                <th style={cs}>Status</th>
              </tr>
              {groups.map(group => {
                let state = scribeState.find(s => s.id == group.id);
                let isUploaded = updatedLines.findIndex(u => u == group.id) != -1;
                let message = isUploaded ? state.errorMessage : "No changes on this group";
                return (<tr>
                    <td style={csb}>{group.lineNumber}</td>
                    <td style={csb}>{group.description}</td>
                    <td style={cs}>{message == null ? <UI.Icon icon={icons.spinner} spinning={true} /> : <span>{message}</span>}</td>
                  </tr>);
                }
              )}
              <tr>
                <td colspan="3" style={tc}>Rows:</td>
              </tr>
              <tr>
                <th style={csb}>Line Number</th>
                <th style={csb}>Item</th>
                <th style={cs}>Status</th>
              </tr>
              {lines.map(line => {
                let state = scribeState.find(s => s.id == line.id);
                let isUploaded = updatedLines.findIndex(u => u == line.id) != -1;
                let message = isUploaded ? state.errorMessage : "No changes on this line";
                return (<tr>
                    <td style={csb}>{line.lineNumber}</td>
                    <td style={csb}>{line.description}</td>
                    <td style={cs}>{message == null ? <UI.Icon icon={icons.spinner} spinning={true} /> : <span>{message}</span>}</td>
                  </tr>);
                }
              )}
            </table>
          </div>
        }

        {isComplete &&
          <div>Quote creation complete!</div>
        }
        <div>It is now safe to close this window {!isComplete && <span> - the upload process will continue</span>}</div>
      </div>
    );
  };
}

export default CrmUpload;

import { CopyCustomLine, CustomLine } from './lines/CustomLine';
import { CopyHeaderLine, HeaderLine } from './lines/HeaderLine';
import { CopyMaterialLine, MaterialLine } from './lines/MaterialLine';
import { AdvancedPricing, Catalog, Search } from './lines/ProductLine';
import { CopyQuoteLine, QuoteLine } from './lines/QuoteLine';
import { RevisionLineDefinition } from './lines/RevisionLineDefinition';
import { CopySystemLine, SystemLine } from './lines/SystemLine';

import { EU, ES, Total } from './prices';

import { Disable, Hide } from './internals';

import CrmUploadPlugin from './plugins/quote/crm-upload-quote';
import MultiEdit from './plugins/quote/multi-edit/';

export const solution: Quote.Solution = {
  internalAppSettings: [
    Hide( 'import' ),
    Hide( 'excel' ),
    Hide( 'revision' ),
    Hide( 'archive' ),
    Hide( 'print' ),
    Hide( 'pricing' ),
    Hide( 'approvals' ),
    {
      id: "upload",
      show: ctx => ctx.userClaims.some(u => u.type === "http://configit.com/claims/allaccess" && u.value === "True")
    }
  ],
  plugins: {
    quote: [CrmUploadPlugin, MultiEdit],
    line: []
  },
  appBar: {
    logo: {}
  },
  home: {},
  quoteSettings: {
    pricingBundles: [ EU, ES, Total ]
  },
  quoteLineDefinitions: {
    quoteList: QuoteLine,
    copyLinesQuoteList: CopyQuoteLine,
    customLine: CustomLine,
    copyLinesCustomLine: CopyCustomLine,
    headerLine: HeaderLine,
    copyLinesHeaderLine: CopyHeaderLine,
    materialLine: MaterialLine,
    copyLinesMaterialLine: CopyMaterialLine,
    systemLine: SystemLine,
    copyLinesSystemLine: CopySystemLine
  },
  productLineDefinitions: {
    catalog: Catalog,
    search: Search,
    advancedPricing: AdvancedPricing
  },
  revisionLineDefinitions: RevisionLineDefinition,
};

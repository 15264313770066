const featureSettings: Quote.FeatureSettings = {
  featureStringSign: function featureStringSign() {
    return ':';
  },
  featureStringSeperator: function featureStringSeperator() {
    return ' ';
  }
};

export default featureSettings;

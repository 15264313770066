export const SystemLine: Quote.SingleLineDefinitions = [{
  colSpan: 6,
  property: 'readOnlyName',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: '100%',
  display: 'inline-block'
}, {
  colSpan: 1,
  property: ''
}, {
  colSpan: 1,
  align: 'right',
  property: ['systemConfigurationStatus', 'attachment'],
  separator: ' ',
}, {
  colSpan: 4,
  align: 'right',
  property: 'price',
  maxWidth: 'calc(100% - 30px)',
  display: 'inline-block'
}];

export const CopySystemLine: Quote.SingleLineDefinitions = [{
  colSpan: 5,
  property: 'name'
}, {
  colSpan: 4,
  property: 'attachment'
}, {
  colSpan: 3,
  align: 'right',
  property: 'price',
  maxWidth: '100%',
  display: 'inline-block',
  textOverflow: 'ellipsis',
  overflow: 'hidden'
}];

// DO NOT MODIFY! Changes should be done in solutions.ts
import { solution } from './solutions';

import defaultApplicationBar from './defaults/applicationBar';
import defaultApprovalCheckSettings from './defaults/approvalCheckSettings';
import defaultAttachmentAndNoteDefaults from './defaults/attachmentAndNoteDefaults';
import defaultConfigurationSettings from './defaults/configurationSettings';
import defaultErrorTranslations from './defaults/errorTranslations';
import defaultFeatureSettings from './defaults/featureSettings';
import defaultFilters from './defaults/filters';
import defaultHomePageElement from './defaults/homePageElement';
import defaultLoginTranslations from './defaults/loginTranslations';
import defaultModelProperty from './defaults/modelProperty';
import defaultPagination from './defaults/pagination';
import defaultPartnerDefinition from './defaults/partnerDefinition';
import defaultQuoteDetailsDefinitions from './defaults/quoteDetailsDefinitions';
import defaultSearchCriteria from './defaults/searchCriteria';

// stop ts compiler from complaining, when added
// new properties to the DOM.
declare global {
  // tslint:disable-next-line: interface-name
  interface Window {
    pagination: Quote.Pagination;
    plugins: {
      quote(): Quote.PluginType[],
      line(): Quote.PluginType[]
    };
    appBar: Quote.ApplicationBar;
    home: Quote.HomeElement;
    filters: Quote.Filter[];
    quoteSettings: Quote.QuoteSettings;
    quoteLineDefinitions: Quote.QuoteLineDefinitions;
    productLineDefinitions: Quote.ProductLineDefinitions;
    revisionLineDefinitions: Quote.RevisionLineDefinition;
    configurator: Quote.ConfigurationSetttings;
    internalAppsSettings: Quote.InternalAppSettings;
    modelProperty: Quote.ModelProperty;
    featureSettings: Quote.FeatureSettings;
    systemSettings: Quote.SystemSettings;
    searchCriteria: Quote.SearchCriteria[];
    buildIntegrationServerPath: string;
    buildApplication: string;
    approvalCheckSettings: Quote.ApprovalSettings;
    partnerDefinition: Quote.PartnerDefinition;
    login: Quote.LoginTranslation;
    error?: Quote.ErrorTranslation;
    quoteDetailsDefinitions: Quote.QuoteDetailsDefinition;
  }

  var window: Window;
}

const defaultBuildIntegrationServerPath: string = 'http://localhost:8080/configuratorPortal/controller';

window.plugins = {
  quote() {
    return solution.plugins.quote || [];
  },
  line() {
    return solution.plugins.line || [];
  }
};
window.quoteSettings = {
  pricingBundles: solution.quoteSettings.pricingBundles,
  attachmentAndNoteDefaults: Object.assign( {},
    defaultAttachmentAndNoteDefaults,
    solution.quoteSettings.attachmentAndNoteDefaults )
};
window.quoteLineDefinitions = solution.quoteLineDefinitions;
window.productLineDefinitions = solution.productLineDefinitions;
window.revisionLineDefinitions = solution.revisionLineDefinitions;
window.internalAppsSettings = {
  /** Returns an array of customizations for the integrated apps in the quote details section.
   * If the array is empty, the apps will keep their default settings.
   */
  quote: function quote() {
    return solution.internalAppSettings || [];
  }
};

window.appBar = Object.assign( {}, defaultApplicationBar, solution.appBar );
window.home = Object.assign( {}, defaultHomePageElement, solution.home );
window.modelProperty = Object.assign( {}, defaultModelProperty, solution.modelProperty );
window.filters = Object.assign( {}, defaultFilters, solution.filters );
window.featureSettings = Object.assign( {}, defaultFeatureSettings, solution.featureSettings );
window.pagination = Object.assign( {}, defaultPagination, solution.pagination );
window.configurator = Object.assign( {}, defaultConfigurationSettings, solution.configurationSettings );
window.approvalCheckSettings = Object.assign( {}, defaultApprovalCheckSettings, solution.approvalCheckSettings );
window.searchCriteria = Object.assign( {}, defaultSearchCriteria, solution.searchCriteria );
window.partnerDefinition = Object.assign( {}, defaultPartnerDefinition, solution.partnerDefinition );
window.buildIntegrationServerPath = solution.buildIntegrationServerPath || defaultBuildIntegrationServerPath;

window.login = Object.assign( {}, defaultLoginTranslations, solution.loginTranslations );
// window.error = Object.assign( {}, defaultErrorTranslations, solution.errorTranslations );
window.quoteDetailsDefinitions = Object.assign( {}, defaultQuoteDetailsDefinitions, solution.quoteDetailsDefinitions );

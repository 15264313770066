export const MaterialLine: Quote.SingleLineDefinitions = [{
  colSpan: 3,
  property: 'materialVariant.code'
}, {
  colSpan: 5,
  property: ['longDescription', 'messages'],
  separator: ' '
}, {
  colSpan: 1,
  align: 'right',
  property: ['configurationStatus', 'attachment'],
  styleStates: {
    normal: {

    },
    deactivateConfig: {
      style: {
        visibility: "hidden"
      }
    }
  },
  getState: function(context) {
    var cat = context.line.getPropertyValue("MaterialCategory");
    if (cat == undefined || cat == "")
      cat = context.line.materialVariant.material.generalItemCategoryGroup;
    
    if (cat == "ZB82")
      return "deactivateConfig";
    return "normal";
  }
}, {
  colSpan: 3,
  align: 'right',
  property: 'price'
}];

export const CopyMaterialLine: Quote.SingleLineDefinitions = [{
  colSpan: 3,
  property: 'materialVariant.code'
}, {
  colSpan: 4,
  property: 'longDescription'
}, {
  colSpan: 2,
  align: 'right',
  property: ['messages', 'configurationStatus', 'attachment'],
  separator: ' '
}, {
  colSpan: 3,
  align: 'right',
  property: 'price'
}];
